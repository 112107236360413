import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';

function Home() {

  const [membership, setMembership] = useState({});
  const [store, setStore] = useState({});
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const auth = useAuth0();

  const changePassword = async () => {
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    const response = await fetch(env.AUTH0_API_SERVER+"change-password", {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        client_id: env.AUTH0_CLIENT,
      }),
    }).then((res) => res.json());
    window.open(response.ticket, '_blank');
  };

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('store')) {
        setLoading(true);
        const accessToken = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: env.AUTH0_AUDIENCE,
            scope: "read:current_user",
          },
        });
        const localMembership = await fetch(env.AUTH0_API_SERVER+"membership/"+window.sessionStorage.getItem('store'), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setMembership(localMembership);
        const localStore = await fetch(env.AUTH0_API_SERVER+"info/"+window.sessionStorage.getItem('store'), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setStore(localStore);
        setLoading(false);
      }
    };
    initData();
  }, [auth])

  return (
    <section id="home">
      {!loading && (membership?.role === 'admin' || membership?.role === 'user') && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('active_services')}</h2>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
            </tr>
          </thead>
          {store.services && Object.values(store.services).length > 0 && <tbody>
            {JSON.parse(window.sessionStorage.getItem('services')).filter((s) => !!store.services[s.code]).map((v, k) => (
              <tr key={k}>
                <td>
                  <h3><a className="item-button-link" href={'/'+v.code}>{t(v.code)}</a></h3>
                </td>
              </tr>
            ))}
          </tbody>}
        </table>
      </div>}
      {!loading && (membership?.role === 'admin' || membership?.role === 'user') && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('settings')}</h2>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h3>{t('store')}</h3>
              </td>
              <td>
                <a className="btn item-button" href="/store">{t('manage')}</a>
              </td>
            </tr>
            {membership?.role === 'admin' && <tr>
              <td>
                <h3>{t('notifications')}</h3>
              </td>
              <td>
                <a className="btn item-button" href="/notifications">{t('manage')}</a>
              </td>
            </tr>}
            {membership?.role === 'admin' && <tr>
              <td>
                <h3>{t('services')}</h3>
              </td>
              <td>
                <a className="btn item-button" href="/services">{t('manage')}</a>
              </td>
            </tr>}
            {membership?.role === 'admin' && <tr>
              <td>
                <h3>{t('catalog')}</h3>
              </td>
              <td>
                <a className="btn item-button" href="/catalog">{t('manage')}</a>
              </td>
            </tr>}
            {membership?.role === 'admin' && store.preferences?.chat && <tr>
              <td>
                <h3>{t('chats')}</h3>
              </td>
              <td>
                <a className="btn item-button" href="/chats">{t('manage')}</a>
              </td>
            </tr>}
            {false && membership?.role === 'admin' && <tr>
              <td>
                <h3>{t('users')}</h3>
              </td>
              <td>
                <a className="btn item-button" href="/users">{t('manage')}</a>
              </td>
            </tr>}
          </tbody>
        </table>
      </div>}
      {!loading && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('profile')}</h2>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('email')}</td>
              <td>{auth.user.name}</td>
            </tr>
            <tr>
              <td>{t('password')}</td>
              <td><button className="btn btn-sm item-button" onClick={() => changePassword()}>{t('change_password')}</button></td>
            </tr>
          </tbody>
        </table>
      </div>}
      {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
    </section>
  );
}

export default Home;
