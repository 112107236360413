import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useParams, useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import Swal from 'sweetalert2';

function Catalog() {

  const [membership, setMembership] = useState({});
  const [store, setStore] = useState({});
  const [page, setPage] = useState(1);
  const [range, setRange] = useState([]);
  const [slice, setSlice] = useState([]);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [pricePrepaid, setPricePrepaid] = useState('');
  const [priceGiftCard, setPriceGiftCard] = useState('');
  const [pointsGained, setPointsGained] = useState('');
  const [validFrom, setValidFrom] = useState('');
  const [validTo, setValidTo] = useState('');
  const [order, setOrder] = useState(0);
  const [image, setImage] = useState('');
  const [active, setActive] = useState('1');
  const [catalogUpdate, setCatalogUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { id } = useParams();

  const navigate = useNavigate();

  const auth = useAuth0();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const upsertCatalog = async () => {
    setLoading(true);
    setCatalogUpdate(false);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"catalogs/"+store.code+(id ? "/"+id : ""), {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        name: name,
        price: price,
        price_prepaid: pricePrepaid,
        price_gift_card: priceGiftCard,
        points_gained: pointsGained,
        valid_from: validFrom ? new Date(validFrom).valueOf() : '',
        valid_to: validTo ? new Date(validTo).valueOf() : '',
        order: order,
        active: active === '1',
        image: image,
      }),
    }).then((res) => res.json());
    setCatalogUpdate(true);
    if (id) {
      setName(name);
      setPrice(price);
      setPricePrepaid(pricePrepaid);
      setPriceGiftCard(priceGiftCard);
      setPointsGained(pointsGained);
      setValidFrom(validFrom);
      setValidTo(validTo);
      setOrder(order);
      setImage(image);
      setActive(active);
      setSlice([
        {
          name: name,
          price: price,
          price_prepaid: pricePrepaid,
          price_gift_card: priceGiftCard,
          points_gained: pointsGained,
          valid_from: validFrom ? new Date(validFrom).valueOf() : '',
          valid_to: validTo ? new Date(validTo).valueOf() : '',
          order: order,
          image: image,
          active: active === '1',
        }
      ]);
    } else {
      setName('');
      setPrice('');
      setPricePrepaid('');
      setPriceGiftCard('');
      setPointsGained('');
      setValidFrom('');
      setValidTo('');
      setOrder(0);
      setImage('');
      setActive('1');
    }
    setLoading(false);
  };

  const deleteCatalog = async () => {
    setLoading(true);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"catalogs/"+store.code+"/"+id, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'delete',
    }).then((res) => res.json());
    setName('');
    setPrice('');
    setPricePrepaid('');
    setPriceGiftCard('');
    setPointsGained('');
    setValidFrom('');
    setValidTo('');
    setOrder(0);
    setImage('');
    setActive('1');
    setLoading(false);
    navigate("/catalog");
  };

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('store')) {
        setLoading(true);
        const accessToken = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: env.AUTH0_AUDIENCE,
            scope: "read:current_user",
          },
        });
        const localMembership = await fetch(env.AUTH0_API_SERVER+"membership/"+window.sessionStorage.getItem('store'), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setMembership(localMembership);
        const localStore = await fetch(env.AUTH0_API_SERVER+"info/"+window.sessionStorage.getItem('store'), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setStore(localStore);
        if (id) {
          const catalog = await fetch(env.AUTH0_API_SERVER+"catalogs/"+window.sessionStorage.getItem('store')+"/"+id, {
            headers: {
              "Content-Type":"application/json",
              "Authorization": "Bearer "+accessToken,
            },
          }).then((res) => res.json());
          setName(catalog.name);
          setPrice(catalog.price);
          setPricePrepaid(catalog.price_prepaid);
          setPriceGiftCard(catalog.price_gift_card);
          setPointsGained(catalog.points_gained);
          setValidFrom(catalog.valid_from ? new Date(catalog.valid_from).toISOString().split('T')[0] : '');
          setValidTo(catalog.valid_to ? new Date(catalog.valid_to).toISOString().split('T')[0] : '');
          setOrder(catalog.order);
          setImage(catalog.image);
          setActive(catalog.active ? '1' : '0');
          setRange([]);
          setSlice([catalog]);
        } else {
          const catalogs = await fetch(env.AUTH0_API_SERVER+"catalogs/"+window.sessionStorage.getItem('store'), {
            headers: {
              "Content-Type":"application/json",
              "Authorization": "Bearer "+accessToken,
            },
          }).then((res) => res.json());
          const rowsPerPage = isMobile ? 5 : 10;
          const localRange = [];
          for (let i = 1; i <= Math.ceil(catalogs.length / rowsPerPage); i++) {
            localRange.push(i);
          }
          setRange([...localRange]);
          const localSlice = catalogs.slice((page - 1) * rowsPerPage, page * rowsPerPage);
          setSlice([...localSlice]);
          if (localSlice.length < 1 && page !== 1) {
            setPage(page - 1);
          }
        }
        setLoading(false);
      }
    };
    initData();
  }, [auth, page, id])

  return (
    <section id="catalog">
      {!loading && membership?.role === 'admin' && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('catalog')}</h2>
        <div className="mt-5">
          <table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            {slice.length > 0 && <tbody>
              {slice.map((el, k) => (
                <tr key={k}>
                  <td style={{width: '200px',}}>
                    {t('name')}:<br />
                    {t('price')}:<br />
                    {t('price')} {t('prepaid')}:<br />
                    {t('price')} {t('gift-card')}:<br />
                    {t('points_gained')}:<br />
                    {t('start')}:<br />
                    {t('end')}:<br />
                    {t('active')}:<br />
                    {t('order')}:<br />
                    {t('image')}:<br />
                  </td>
                  <td style={{width: '200px',}}>
                    {el.name}<br />
                    {(el.price*1).toFixed(2)} €<br />
                    {(el.price_prepaid*1).toFixed(2)} €<br />
                    {(el.price_gift_card*1).toFixed(2)} €<br />
                    {el.points_gained}<br />
                    {el.valid_from ? new Date(el.valid_from).toLocaleDateString([], {year: "numeric", month: "2-digit", day: "2-digit"}) : ''}<br />
                    {el.valid_to ? new Date(el.valid_to).toLocaleDateString([], {year: "numeric", month: "2-digit", day: "2-digit"}) : ''}<br />
                    {el.active ? t('yes') : t('no')}<br />
                    #{el.order}<br />
                    {el.image ? <img src={el.image} alt='' height='100' /> : t('no_image')}<br />
                  </td>
                  <td style={{width: '50px',}}>
                    {!id && <a className="btn btn-sm item-button" href={'/catalog/'+el.id}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark" viewBox="0 0 16 16">
                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
                      </svg>
                    </a>}
                  </td>
                </tr>
              ))}
            </tbody>}
          </table>
        </div>
        {slice.length === 0 && <span>{t('no_catalog')}</span>}
        <div>
          {range.map((el, index) => (
            (page === el || page === el-1 || page === el+1 || index === 0 || index === range.length-1) && <button key={index} className={page === el ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => setPage(el)}>{(page === el+1 && index !== 0) || (page === el-1 && index !== range.length-1) ? '...' : el}</button>
          ))}
        </div>
        {id && <a className="btn btn-lg item-button" href={'/catalog'}>{t('back')}</a>}
      </div>}
      {!loading && membership?.role === 'admin' && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{id ? t('update') : t('create')}</h2>
        <table className="table">
          <tbody>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('name')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={name} onChange={(e) => setName(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('price')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="number" step="0.01" inputMode="numeric" pattern="[0-9]*" min="0" value={price} onChange={(e) => setPrice(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('price_prepaid')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="number" step="0.01" inputMode="numeric" pattern="[0-9]*" min="0" value={pricePrepaid} onChange={(e) => setPricePrepaid(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('price_gift_card')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="number" step="0.01" inputMode="numeric" pattern="[0-9]*" min="0" value={priceGiftCard} onChange={(e) => setPriceGiftCard(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('points_gained')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="number" step="1" inputMode="numeric" pattern="[0-9]*" min="0" value={pointsGained} onChange={(e) => setPointsGained(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('start')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="date" value={validFrom} onChange={(e) => setValidFrom(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('end')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="date" value={validTo} onChange={(e) => setValidTo(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('active')}</p>
              </td>
              <td>
                <select className="affiliation-input" value={active} onChange={e => setActive(e.target.value)}>
                  <option value="0">{t('no')}</option>
                  <option value="1">{t('yes')}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('order')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="number" step="1" inputMode="numeric" pattern="[0-9]*" min="0" value={order} onChange={(e) => setOrder(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('image')}</p>
              </td>
              <td>
                {(!id || !image) && <input className="affiliation-input" type="file" onChange={e => Resizer.imageFileResizer(e.target.files[0], 256, 256, "PNG", 100, 0, (x) => setImage(x), "base64", 256, 256)} />}
                {image && <img src={image} alt='' height='100' />}
                {image && <button className="btn btn-sm item-button-danger" onClick={ () => setImage('') } data-bs-toggle="tooltip" data-bs-placement="right" title={t('remove')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                  </svg>
                </button>}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-3">
          <button disabled={!name || !price} className="btn btn-lg item-button" onClick={() => confirm(() => upsertCatalog())}>{t('confirm')}</button>
        </div>
        {catalogUpdate && <small>{t('updated_catalog')}</small>}
      </div>}
      {!loading && membership?.role === 'admin' && <div className="affiliation-section">
        {id && <div className="mt-3">
          <button className="btn btn-lg item-button-danger" onClick={() => confirm(() => deleteCatalog())}>{t('remove')}</button>
        </div>}
      </div>}
      {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
    </section>
  );
}

export default Catalog;
